.e-nav {
    align-items: center;
	background: rgba(0,0,0,0.9);
    display: flex;
	height: 100%;
    justify-content: center;
    opacity: 0;
	position: fixed;
    left: 0;
    top: 0;
    transition: $transition--fast;
    visibility: hidden;
	width: 100%;
	z-index: 90000;

    &.is--open {
        opacity: 1;
        visibility: visible;
    }

    
    &__close {
        position: absolute;
        right: 2rem;
        top: 2rem;
        height: 3rem;
        width: 3rem;
        
        &:after {
            content: "";
            height: 3rem;
            display: block;
            left: 1rem;
            position: absolute;
            width: 2px;
            background: #999;
            transform: rotate(45deg);
        }

        &:before {
            content: "";
            left: 1rem;
            display: block;
            position: absolute;
            height: 3rem;
            width: 2px;
            background: #999;
            transform: rotate(-45deg);
        }
    }
    
    &__list {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        
        > li {
            font-size: 3rem;
            color: white;
            line-height: 1.65;
            
            @media screen and (max-width: 700px) and (orientation: landscape){
                font-size: 2rem;
            }            
            
            @media screen and (max-width: 400px){
                font-size: 2.5rem;
            }
            
            > a {
                text-decoration: none;
                color: white;
            
                &.is--active {
                    border-bottom: .25rem solid white;
                }
            }
        }
    }
}