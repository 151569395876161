.c-article {
    height: 100vh;
    position: relative;
    text-align: center;
    width: 100%;
    
    @include media('<=small'){
        height: 590px;
    } 
    
    &:after {
        background-color: rgba(255, 255, 255, .6);
        content: '';
        display: block;
        height: calc(100% - 12.5rem);
        opacity: 0;
        position: absolute;
        top: 7.5rem;
        left: 0;
        bottom: 5rem;
        transition: $transition--fast;
        visibility: hidden;
        width: calc(100% - 10%);
        z-index: 105;
    }

    &.is--loading:after {
        opacity: 1;
        visibility: visible;
    }
    
    &__picture {        
        figure {
            height: 100%;
            width: 100%;
        }
        
        @include media('<=medium'){
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            position: absolute;
        }
        
        @include media('<=small'){
            position: relative;
            transform: none;
            top: 0;
            left: 0;
        }
    }

    &__section {
        opacity: 0;
        position: absolute;
        top: 7.5rem;
        left: 0;
        bottom: 5rem;
        height: calc(100vh - 12.5rem);
        visibility: hidden;
        width: 100%;
        
        @include media('<=medium'){
            top: 2rem;
        }

        &.is--active {
            opacity: 1;
            visibility: visible;
        }

        &.is--next {
            opacity: 0;
            visibility: visible;
        }
    }

    img {
        position: absolute;
        max-width: 100%;
        max-height: 100%;
        transform: translate(-50%,-50%);
        transition: $transition--fast;
        top: 50%;
    }

    
    @include media('<=medium'){
        img {
            max-width: 315px;
            position: static;
            transform: none;
        }
    }
}