.e-loader {
    display: block;
    height: 6.25rem;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: $transition--fast;
    visibility: hidden;
    width: 6.25rem;
    z-index: 110;

    .is--loading & {
        opacity: 1;
        transition: $transition--fast;
        visibility: visible;
    }

    &:before,
    &:after {
        content: "";
        position: absolute;
        top: -.625rem;
        left: -.625rem;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        border: .3125rem solid transparent;
        border-top-color: rgba(156, 205, 252, 1);
    }

    &:before {
        z-index: 100;
        animation: spin .7s linear;
        animation-iteration-count: infinite;
    }

    &:after {
        border: .3125rem solid rgba(255, 255, 255, .9);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}