.o-row {
  max-width: 90rem;
  margin: 0 auto;

  &__stretch {
    max-width: 100%;
    margin: 0;
  }

  &__full-height {
    height: 100vh;
    overflow: hidden;

    @include media('<=small') {
      height: auto !important;
    }
  }

  .o-row {
    margin-left: -$fg-gutter;
    margin-right: -$fg-gutter;
    max-width: none;
    width: auto;
  }

  &__collapse > [class^='o-col__'] {
    padding-left: 0;
    padding-right: 0;
  }

  &__spacing {
    padding: 5rem 0;

    &-border {
      padding: 10rem;

      @include media('<=medium') {
        padding: 5rem;
      }

      @include media('<=small') {
        padding: 2rem;
      }
    }

    &-bigimp {
      padding: 8.3333% 22%;

      a {
        color: black !important;
      }
    }

    &-big {
      padding: 8.3333333% 22% 0;

      a {
        color: black !important;
      }

      @include media('<=medium') {
        padding: 5rem 0 0 0;
      }
    }

    &-top {
      padding: 15rem 0 5rem;

      @include media('<=medium') {
        padding: 2rem 0;
      }
    }

    &-background {
      padding-bottom: 35%;
      background: url('../img/content/about_shots.jpg') no-repeat bottom center /
        100% auto;

      @include media('<=medium') {
        padding-bottom: 50%;
      }
    }
    &-backgroundabout {
      padding-bottom: 25%;
      background: url('../img/content/about_shots2.jpg') no-repeat bottom center /
        100% auto;

      @include media('<=medium') {
        padding-bottom: 30%;
      }
    }

    &-backgroundabout__australia {
      background: url('../img/content/about_australia.jpg');
      background-size: contain;
      padding-bottom: 15%;
      margin-bottom: 10%;

      h1 {
        font-size: 5rem;
        color: #79dea8;
        mix-blend-mode: multiply;
        opacity: 0.8;

        @include media('<=medium') {
          font-size: 3rem;
        }
      }

      img {
        mix-blend-mode: multiply;
        opacity: 0.8;
        width: 50rem;
      }
      @include media('<=medium') {
        padding-bottom: 30%;
      }
    }

    &-infographic {
      background: #baccdd;
      text-align: center;
    }

    &-infographic2 {
      background: #ead0d3;
      text-align: center;
    }
  }

  .o-col__vertical {
    justify-content: center;
  }
}
