@font-face {
    font-family: 'HK Grotesk';
    src: url($path__fonts + 'HKGrotesk-Regular.eot');
    src: url($path__fonts + 'HKGrotesk-Regular.eot?#iefix') format('embedded-opentype'),
         url($path__fonts + 'HKGrotesk-Regular.woff') format('woff'),
         url($path__fonts + 'HKGrotesk-Regular.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'HK Grotesk';
    src: url($path__fonts + 'HKGrotesk-Medium.eot');
    src: url($path__fonts + 'HKGrotesk-Medium.eot?#iefix') format('embedded-opentype'),
         url($path__fonts + 'HKGrotesk-Medium.woff') format('woff'),
         url($path__fonts + 'HKGrotesk-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'HK Grotesk';
    src: url($path__fonts + 'HKGrotesk-Bold.eot');
    src: url($path__fonts + 'HKGrotesk-Bold.eot?#iefix') format('embedded-opentype'),
         url($path__fonts + 'HKGrotesk-Bold.woff') format('woff'),
         url($path__fonts + 'HKGrotesk-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
  font-family: 'fontello';
  src: url($path__icon-fonts + 'fontello.eot?8926641');
  src: url($path__icon-fonts + 'fontello.eot?8926641#iefix') format('embedded-opentype'),
       url($path__icon-fonts + 'fontello.woff2?8926641') format('woff2'),
       url($path__icon-fonts + 'fontello.woff?8926641') format('woff'),
       url($path__icon-fonts + 'fontello.ttf?8926641') format('truetype'),
       url($path__icon-fonts + 'fontello.svg?8926641#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
}

$icons: (
    cancel: '\e800',
    ok: '\e801',
    minus: '\e802',
    search: '\e803',
    plus: '\e817',
    mail-alt: '\f0e0',
    angle-left: '\f104',
    angle-right: '\f105',
    angle-up: '\f106',
    angle-down: '\f107'
);