.c-breadcrumb {
    white-space: nowrap;

    &__list {
        @include clearfix();
        overflow: hidden;
    }

    &__item {
        display: none;
        line-height: .8;
        overflow: hidden;

        &--show {
            display: inline-block;
        }

        &--ellipsis {
            text-overflow: ellipsis;
        }

        &:last-child .c-breadcrumb__title {
            color: $color--primary;
            text-decoration: underline;

            &:after {
                display: none;
            }
        }
    }

    &__link {
        padding: 0 1.25rem 0 .625rem;
        text-decoration: none;
    }

    &__title {
        @include icon(after, 'angle-right');
        color: $color--secondary;
        position: relative;
        transition: $transition--fast;

        &:after {
            position: absolute;
            top: .25rem;
            right: -1rem;
        }
        
        &:hover {
            color: $color--primary;
        }
    }
}