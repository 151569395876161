.e-layer {
    align-items: center;
	background: rgba(0,0,0,0.35);
    display: flex;
	height: 100%;
    justify-content: center;
    opacity: 0;
	position: fixed;
    left: 0;
    top: 0;
    transition: $transition--fast;
    visibility: hidden;
	width: 100%;
	z-index: 99999999999;

    &.is--open {
        opacity: 1;
        visibility: visible;
    }

    &__inner {
        background-color: #FFF;
        padding: 4rem;
        position: relative;
        max-width: 48rem;
        text-align: center;
        transform: scale(0);
        transition: $transition--fast;

        .is--open & {
            transform: scale(1);
        }
    }

    &__close {
        position: absolute;
        right: 2rem;
        top: 2rem;
        height: 2rem;
        width: 2rem;
        cursor: pointer;

        &:after {
            content: "";
            height: 2rem;
            display: block;
            left: 1rem;
            position: absolute;
            width: 2px;
            background: #999;
            transform: rotate(45deg);
        }

        &:before {
            content: "";
            left: 1rem;
            display: block;
            position: absolute;
            height: 2rem;
            width: 2px;
            background: #999;
            transform: rotate(-45deg);
        }
    }

    &__list {
        margin-top: 2rem;
        &-item {
            display: inline-block;

            img {
                width: 80%;
            }
        }
    }

    &__inner{
        display: table-cell;
        position: relative;
        z-index: 110;
        vertical-align: middle;
    }

    &__text {
        font-size: 1.15rem;
        font-weight: 300;
        line-height: 1.75;
    }

    &__content {
        max-width: 48rem;
        margin: 0 auto;
        background: white;
        padding: 4rem;
        position: relative;
    }

    &__headline {
        font-size: 2.5rem;
    }
}