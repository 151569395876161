.o-content {
    &--margin {
        margin-bottom: 1.25rem;
    }

    &--match {
        align-items: stretch;
        height: 100%;
        @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
            height: calc(100% - 1.25rem);
        }
    }

    > *:last-child {
        margin-bottom: 0;
    }
}

.o-section {
    &__colorover {
        &:after {
            content: "";
            display: block;
            position: absolute;
            right: 0;
            top: 0;
            bottom: 0;
            left: 45%;
            background: rgba(#9ccdfc,0.25);
            z-index: -5;
            
            @include media('<=medium'){
                display: none;
            }
        }
    }
    
    &__bg {
        position: relative;
        
        &:before {
            content: "";
            left: 0;
            top: 0;
            bottom: 0;
            right: 83.33333%;
            background: url("../img/content/ab_bg-before.jpg") no-repeat left center / contain;
            position: absolute;
            
            @include media('<=medium'){
                display: none;
            }
        }      
        
        &:after {
            content: "";
            right: 0;
            top: 0;
            bottom: 0;
            left: 83.33333%;
            background: url("../img/content/ab_bg-after.jpg") no-repeat right center / contain;
            position: absolute;
            
            @include media('<=medium'){
                display: none;
            }
        }
    }
}