.o-userbar {
    position: fixed;
    left: 0;
    bottom: 0;
    right: 0;
    background: white;
    box-shadow: 0 0 16px rgba(0,0,0,0.15);
    
    @media screen and (max-width: 767px) {
        position: relative;
        margin-top: 4rem;
    }
}