.c-infographic {
     position: relative;
    
    @include media('<=small'){
        padding: 2rem 0;
    }
    
    &__item {
        @include media('<=small'){
            margin-bottom: 2rem;
        }
    }
    
    &__half {
        position: absolute;
        bottom: 0;
        top: 0;
        
        @include media('<=small'){
            position: static;
            text-align: center;
        }
        
        &.is--right {
            right: 0;
            left: 50%;
            text-align: left;
            
            @include media('<=small'){
                text-align: center;
            }
            
            .c-infographic__fact {
                padding-left: 250px;
                margin-left: 10%;
                position: absolute;
                top: 55%;
                
                @include media('<=small'){
                    position: static;
                    margin: 0;
                    padding: 0;
                    margin-bottom: 1rem;
                }
                
                &:after {
                    content: "";
                    position: absolute;
                    left: 0%;
                    top: 50%;
                    transform: translateY(-50%);
                    height: 24px;
                    width: 24px;
                    background: #2e2845;
                    border-radius: 100%;
                    
                    @include media('<=small'){
                        display: none;
                    }
                }
                
                &:before {
                    content: "";
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    height: 3px;
                    width: 35%;
                    left: 0%;
                    background: #2e2845;
                    
                    @include media('<=xxlarge'){
                        width: 35%;
                    }
                    
                    @include media('<=small'){
                        display: none;
                    }
                    
                    @include media('<=medium'){
                        width: 30%;
                    }
                }
            }
        }   

        &.is--left {
            right: 50%;
            left: 0;
            text-align: right;
            
            @include media('<=small'){
                text-align: center;
            }
            
            .c-infographic__fact {
                padding-right: 250px;
                margin-right: 7.5%;
                position: absolute;
                right: 0;
                
                @include media('<=small'){
                    position: static;
                    padding: 0;
                    margin: 0;
                    margin-bottom: 1rem;
                }
                
                &.is--top {
                    top: 20%;
                    
                    &:after {
                        right: 0%;
                    }
                }      
                
                &.is--bottom {
                    bottom: 20%;
                    
                    &:after {
                        right: 0%;
                    }
                }
                
                &:after {
                    content: "";
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    height: 24px;
                    width: 24px;
                    background: #2e2845;
                    border-radius: 100%;
                    
                    @include media('<=small'){
                        display: none;
                    }
                } 
                
                &:before {
                    content: "";
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    height: 3px;
                    width: 35%;
                    right: 0%;
                    background: #2e2845;
                    
                    @include media('<=xxlarge'){
                        width: 35%;
                    }
                    
                    @include media('<=small'){
                        display: none;
                    }
                    
                    @include media('<=medium'){
                        width: 30%;
                    }
                }
            }
        }
    }
        
    &__fact {
        &-headline {
            color: #2e2845;
            font-size: 1.75rem;
            margin-bottom: 0.25rem;
            
            @include media('<=small'){
                font-size: 1.5rem;
            }
        }
        
        &-content {
            font-size: 1.25rem;
            color: #89849a;
            line-height: 1.75;
            font-weight: 300;
            
            @include media('<=medium'){
                font-size: 1.15rem;
            }            
            
            @include media('<=small'){
                font-size: 1rem;
            }
        }
    }
}