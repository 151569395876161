.c-logo {
    float: right;
    text-align: right;
    @include media('<=medium') {
        float: none;
        padding: 0.7rem;
    }

    &__link {
        display: inline-block;
        
        img {
            max-width: 75%;
            vertical-align: middle;
        }
    }
}