.c-pagination {
	text-align: center;

	&__list {
		display: inline-block;
		margin-bottom: -.3125rem;
	}

	&__item {
		display: inline;

		&--overflow .c-pagination__status {
			border: .0625rem solid #ddd;
			padding: .375rem .75rem;
			margin-left: -.0625rem;

			&:before {
				content: '...';
			}
		}
	}

	&__link,
	&__status {
		float: left;
		position: relative;
		text-decoration: none;
	}

	&__link {
		border: .0625rem solid #ddd;
		color: #000;
		padding: .375rem .75rem;
		margin-left: -.0625rem;
		width: 2.875em;
		@include media('<xsmall') {
			width: auto;
		}

		.is--active & {
			background-color: $color--primary;
			box-shadow: 0 .0625rem .125rem rgba(0,0,0,.1);
			color: #FFF;
			cursor: default;
			z-index: 2;
		}

		.is--disabled & {
			cursor: not-allowed;
		}

		&--prev {
			@include icon(before, 'angle-left');
		}

		&--next {
			@include icon(before, 'angle-right');
		}

		&--prev,
		&--next {
			color: $color--primary;
			padding-left: .825rem;
			padding-right: .825rem;
			@include media('<xsmall') {
				&:before {
					width: auto;
				}
			}
		}

		&:hover {
			background-color: $color--primary;
		}
	}

	&__status {
		.c-pagination__item--disabled & {
			cursor: not-allowed;
		}
	}
}