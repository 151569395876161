@media print {
    *,
    *::before,
    *::after,
    p::first-letter,
    div::first-letter,
    blockquote::first-letter,
    li::first-letter,
    p::first-line,
    div::first-line,
    blockquote::first-line,
    li::first-line {
        text-shadow: none !important;
        box-shadow: none !important;
    }

    a,
    a:visited {
        text-decoration: underline;
    }

    abbr[title]::after {
        content: " (" attr(title) ")";
    }

    pre {
        white-space: pre-wrap !important;
    }
    pre,
    blockquote {
        border: .0625rem solid #999;
        page-break-inside: avoid;
    }

    thead {
        display: table-header-group;
    }

    tr,
    img {
        page-break-inside: avoid;
    }

    p,
    h2,
    h3 {
        orphans: 3;
        widows: 3;
    }

    h2,
    h3 {
        page-break-after: avoid;
    }

    table {
        border-collapse: collapse !important;

        td,
        th {
            background-color: #fff !important;
        }
    }

    /* Custom */
    .c-nav,
    .c-footer {
        display: none;
    }
}