
// ==========================================================================
// Utilities :: TextEllipsis
// ==========================================================================

%ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

// ==========================================================================
// Utilities :: VerticalAlign
// ==========================================================================

%vertical-align {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}