body {
	@include media('<=medium') {
		padding-top: 3.438rem;
        position: relative;
        
        &.no-padding {
            padding: 0;
            
            @include media('<=medium'){
                padding-top: 3.438rem;
            }
        }

		&:after {
			background-color: rgba(0,0,0,0.6);
			content: '';
			display: block;
			height: 100%;
			position: absolute;
			left: 0;
			right: 0;
			bottom: 0;
			top: 0;
			transition: $transition--fast;
			opacity: 0;
			visibility: hidden;
			width: 100%;
		}

		&.push:after {
			opacity: 1;
			visibility: visible;
		}
    }
}

.c-optimized {
	background-color: #FFF;
	display: table;
	font-family: Consolas, serif;
	height: 100%;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	vertical-align: middle;
	width: 100%;
	z-index: 9999;

	&__inner {
	    display: table-cell;
	    text-align: center;
	    vertical-align: middle;
	}
}