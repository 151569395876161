.c-view {
    text-align: center;
    height: 77px;
    
    @include media('<=medium'){
        height: 74px;
    }
    
    &__inner{
        height: 77px;
    }
    
    &__list {
        display: inline-block;
        height: 77px;
        
        &-item {
            float: left;
            height: 77px;
            vertical-align: middle;
            border-left: 1px solid #EEE;
            font-size: 1rem;
            transition: .25s ease all;
            @include media('<=medium'){
                height: 100%;;
            }
            @include media('<=xsmall'){
                &:first-child {
                    border-left: 0;
                }
            }
            
            > a {
                vertical-align: middle;
                color: #999;
                text-decoration: none;
                width: 100%;
                display: table-cell;
            }

            &:last-child {
                border-right: 1px solid #eee;
                
                @include media('<=xsmall'){
                    border-right: 0;
                }
            }
        }

        &-button {
            background-color: transparent;
            border: 0;
            color: #999;
            display: inline-block;
            font-size: 1rem;
            height: 100%;
            padding: 0 2rem;
            position: relative;
            top: 50%;
            transform: translateY(-50%);
            transition: $transition--fast;
            vertical-align: middle;

            &.is--active {
                background-color: #EEE;
            }
        }
    }
}