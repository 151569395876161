.e-btn {
	background-color: transparent;
	border: 0.15rem solid black;;
	color: black;
	cursor: pointer;
	display: inline-block;
	font-size: 1rem;
	font-weight: 400;
	padding: 1rem 2rem;
	user-select: none;
	text-decoration: none;
	transition: $transition--fast;
    
    @include media('<=small'){
        padding: 0.5rem 1rem;
        font-size: 1rem;
    }

	/** active and hover states **/
	&:hover {
		background-color: black;
        color: white;
	}

	&:active {
		background-color: black;
	}

	/** disable state **/
	&.is--disabled {
		background-color: $bg-button-disabled;
		color: $color-button-disabled;
		cursor: not-allowed;
	}
    
    &.is--blue {
        background: #4573b1;
        border-color: #4573b1;
        color: white;
        padding: 0.75rem 2rem;
    }

	/** close **/
	&.is--close {
		border: 0;
		cursor: pointer;
		display: block;
		height: 2rem;
		padding: 0;
		position: absolute;
		right: 2rem;
		top: 2rem;
		width: 2rem;

		&:before,
		&:after {
			background: #999;
			content: "";
			display: block;
			height: 2rem;
			position: absolute;
			left: 1rem;
			top: 0;
			transition: $transition--fast;
			width: .125rem;
		}

		&:after  {
			transform: rotate(45deg);
		}

		&:before {
			transform: rotate(-45deg);
		}

		&:hover {
			background-color: transparent;

			&:before,
			&:after {
				background-color: #333;
			}
		}
	}

	/** sizes **/
	&.is--mini {
		padding: .25rem .625rem;
	}

	&.is--tiny {
		padding: .425rem .75rem;
	}

	&.is--small {
		padding: .5rem .875rem;
	}

	&.is--medium {
		padding: .625rem 1rem;
	}

	&.is--large {
		padding: .75rem 1.375rem;
	}

	&.is--big {
		padding: .875rem 1.5rem;
	}

	&.is--huge {
		padding: .9375rem 1.625rem;
	}

	&.is--massive {
		padding: 1rem 1.75rem;
	}

	/** sizes(stretch) **/
	&.is--wide {
		padding-left: 2.5rem;
		padding-right: 2.5rem;
	}

	&.is--stretch {
		padding-left: 5rem;
		padding-right: 5rem;
	}

	/** loading **/
	&.is--loading {
		color: transparent;
		position: relative;
		opacity: 1;
		pointer-events: auto;

		&:before {
			position: absolute;
			content: '';
			top: 50%;
			left: 50%;
			margin: -.64285714em 0 0 -.64285714em;
			width: 1.28571429em;
			height: 1.28571429em;
			border-radius: 500rem;
			border: .2em solid rgba(0,0,0,.15);
		}

		&:after {
			animation: spin .6s linear;
			animation-iteration-count: infinite;
			border-radius: 50%;
			border-color: #FFF transparent transparent;
			border-style: solid;
			border-width: .2em;
			box-shadow: 0 0 0 1px transparent;
			content: '';
			height: 1.28571429em;
			position: absolute;
			top: 50%;
			left: 50%;
			margin: -.64285714em 0 0 -.64285714em;
			width: 1.28571429em;
		}
	}
    
    &.is--alternative {
        color: #f06761;
        border-color: #f06761;
        
        &:hover, &:active {
            background: #f06761;
            color: white;
        }
    }

	/** search **/
	&--search {
		@include icon(before, 'search');
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
		float: left;
		height: 2.875rem;
		padding: 0;
		text-align: center;
		width: 2.875rem;
	}
}