.c-logos {
    background: #f1f2f4;
    padding: 5rem 0;
    text-align: center;
    
    @include media('<=small'){
        padding: 2.5rem 0;
    }
    
    &__list{    
        &-item {
            display: inline-block;
            margin: 0 2rem;
            
            @include media('<=small'){
                display: block;
                margin: 2rem;
                
                img {
                    height: 50%;
                }
            }
        }
    }
}