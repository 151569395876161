// ==========================================================================
// Variables - Global :: Colors
// ==========================================================================

$color--primary: #27ae60;
$color--secondary: #292929;

$color--light: #e4e4e4;
$color--text: #8f8f8f;
$color--medium: #f6f7f8;
$color--dark: #f0f0f0;
$color--darkest: #747474;
$color--disabled: #cacbcd;

$color--info: #d9edf7;
$color--success: #dff0d8;
$color--warning: #fcf8e3;
$color--fail: #f2dede;
$color--error: #d8000c;

// ==========================================================================
// Config - Button :: Colors
// ==========================================================================

$bg-button-primary: #27ae60;
$bg-button-disabled: #cacbcd;

$color-button-primary: #fff;
$color-button-disabled: lighten(#292929, 30%);

// ==========================================================================
// Config - Form :: Colors
// ==========================================================================

$bg-form-primary: #fff;
$bg-form-focus: #f9f9f9;

$border-form-primary: #e4e4e4;
$border-form-secondary: #cacbcd;

$color-form-action: $color--primary;
$color-form-success: #2ecc71;
$color-form-error: #e74c3c;

// ==========================================================================
// Variables - Background :: Colors
// ==========================================================================

$color__background--default: #fff;

// ==========================================================================
// Variables - Paths :: Font
// ==========================================================================

$path__fonts: '../../fonts/webfont/';
$path__icon-fonts: '../../fonts/iconfont/';

// ==========================================================================
// Variables - Family :: Font
// ==========================================================================

$font__family--default: 'HK Grotesk', arial, helvetica, sans-serif;
$font__family--icons: 'fontello';

// ==========================================================================
// Variables - Size :: Font
// ==========================================================================

$font__size--default: 1rem; //16px

// ==========================================================================
// Variables - Style :: Font
// ==========================================================================

$font__size--style: normal;

// ==========================================================================
// Variables - Weight :: Font
// ==========================================================================

$font__weight--default: 400;

// ==========================================================================
// Variables - Line-height :: Font
// ==========================================================================

$font__height--default: 1.4;

// ==========================================================================
// Variables - Headlines
// ==========================================================================

$headline__size--h1: 2.125rem; //34px
$headline__size--h2: 1.625rem; //26px
$headline__size--h3: 1.25rem; //20px
$headline__size--h4: 1rem; //18px
$headline__size--h5: 1.063rem; //17px
$headline__size--h6: 1.063rem; //17px

// ==========================================================================
// Variables - Transition
// ==========================================================================

$transition--fast: all 0.3s;
$transition--cubic: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
$transition--slow: all 0.6s;

// ==========================================================================
// Variables - Loader Duration
// ==========================================================================

$duration--fast: 0.25s;
$duration--default: 0.5s;
$duration--slow: 1s;

// ==========================================================================
// Variables - Flexbox :: Grid
// ==========================================================================

$fg-width: 90rem;
$fg-columns: 12 !default;
$fg-gutter: 0.625rem !default;
$fg-class-row: 'o-row' !default;
$fg-class-col: 'o-col' !default;
$fg-class-off: 'o-off' !default;

$fg-breakpoints: (
  (hh),
  (xs, 40rem),
  //640px
  (sm, 48rem),
  //768px
  (md, 64rem),
  //1024px
  (lg, 80rem) //1280px
) !default;

// ==========================================================================
// Variables - MediaQueries :: Grid
// ==========================================================================

// Usage:
// @include media(">small") {
//     ...stuff
// }

$breakpoints: (
  'handheld': 21.5rem,
  // 340px
  'mobile': 33.5rem,
  //536px
  'xsmall': 40rem,
  // 640px
  'small': 48rem,
  // 768px
  'medium': 64rem,
  // 1024px
  'wide': 80rem,
  // 1280px
  'large': 90rem,
  // 1440px
  'screen': 95rem,
  // 1520px
  'xlarge': 100rem,
  // 1600px
  'xxlarge': 1920px // 1920px,
);
