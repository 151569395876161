.c-image {    
    &__picture {
        &.is--right {
            text-align: right;
            margin-right: 5rem;
            
            img {
                margin-bottom: 1rem;
            }
            
            p {
                font-size: 1.15rem;
                font-weight: 300;
            }
            
            @include media('<=medium'){
                margin-right: 0;
                text-align: center;
                padding: 1rem;
                
                img {
                    width: 100%;
                }
            }
        }
    }
}