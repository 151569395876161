.c-header {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    padding: 2rem 10rem;
    z-index: 20;
    
    &__nobg {
        background: transparent;
    }
    
    @include media('<=wide'){
        padding: 2rem 5rem;
        background: white;
    }  
    
    @include clearfix();
    @include media('<=medium') {
        height: 3.438rem;
        padding-top: 0;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 100;
        padding: 0;
        box-shadow: 0 3px 8px rgba(0,0,0,.1);
    }
}