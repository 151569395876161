.c-family {
    &__inner {
        text-align: center;
        position: relative;
        
        &:before {
            content: "";
            display: block;
            position: absolute;
            left: 0;
            top: 0;
            right: 50%;
            bottom: 0;
            background: #ceefe2;
        }
    }
    
    &__text {
        padding: 15rem 0;
        position: relative;
        z-index: 10;
        
        @include media('<=small'){
            padding: 2.5rem ;
        }
        
        
        @include media('<=medium'){
            padding: 8rem 0;
        }
        
        @include media('<=xsmall'){
            padding: 5rem 0;
        }    
        
        &-headline {
            font-size: 6rem;
            font-weight: 700;
            color: #f06761;
            
            @include media('<=medium'){
                font-size: 4rem;
            }            
            
            @include media('<=small'){
                font-size: 2rem;
            }
        }
        
        &-email {
            > a {
                font-size: 1.75rem;
                font-weight: 700;
                color: #f06761;
                text-decoration: none;
            
                @include media('<=small'){
                    font-size: 1rem;
                }
            }
        }
    }
}