.c-nav {
    float: left;
    position: relative;
    @include media('<=medium') {
        background-color: transparent;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        z-index: 101;
        height: 3.438rem;
    }

    &__list {
        width: 100%;
        @include media('<=medium') {
            position: absolute;
            top: 3.438rem;
            left: 0;
            transform: translateX(-100%);
            transition: $transition--fast;
            z-index: 1;

            .push & {
                transform: translateX(0);
            }
        }
    }

    &__item {
        @include clearfix;
        float: left;
        @include media('<=medium') {
            border-right: 0;
            width: 100%;
        }

        &:first-child {
            @include media('<=medium') {
                border-left: 0;
            }
        }
    }
    

    &__link {
        color: #999999;
        display: block;
        padding: 0 1rem;
        height: 3.275rem;
        margin-right: 2rem;
        line-height: 3.275rem;
        text-align: center;
        text-decoration: none;
        text-transform: uppercase;
        font-weight: 600;
        letter-spacing: 0.125rem;
        font-size: 0.85rem;
        border-bottom: 0.2rem solid transparent;
        transition: $transition--fast;

        &:hover {
            color: #000;    
        }
        
        &.is--active {
            color: #000;
            border-color: black;
        }
    }

    &__toggle {
        background-color: transparent;
        border: 0;
        cursor: pointer;
        height: 0;
        position: absolute;
        transform: rotate(0deg);
        transition: $transition--fast;
        opacity: 0;
        visibility: hidden;
        width: 3.438rem;
        z-index: 2;
        @include media('<=medium') {
            height: 3.438rem;
            position: relative;
            opacity: 1;
            visibility: visible;
        }

        &-line {
            opacity: 1;
            backface-visibility: hidden;
            background: #000;
            border-radius: 0.1875rem;
            display: block;
            position: absolute;
            left: 50%;
            height: 0.1875rem;
            transform-style: preserve-3d;
            transform: rotate(0deg);
            -webkit-transform-style: preserve-3d;
            -webkit-transform-style: flat;
            transform: translateZ(0);
            transition: $transition--fast;
            margin-left: -0.7815rem;
            width: 1.563rem;

            &:nth-child(1) {
                top: 1.25rem;

                .is--open & {
                    top: 1.6rem;
                    transform: rotate(135deg);
                }
            }

            &:nth-child(2) {
                top: 1.6rem;

                .is--open & {
                    opacity: 0;
                    left: -0.5rem;
                }
            }

            &:nth-child(3) {
                top: 1.975rem;

                .is--open & {
                    top: 1.6rem;
                    transform: rotate(-135deg);
                }
            }
        }
    }
}
