.c-video {
    &__element {
        height: 0;
        padding-bottom: 67.5%;
        position: relative;
        overflow: hidden;

        &--widescreen {
            padding-top: 0;
            padding-bottom: 56.34%;
            @include media('<xsmall') {
                padding-bottom: 67.5%;
            }
        }
    }

    embed,
    iframe,
    object,
    video {
        border: 0;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
    }
}