.e-alert {
    + .e-alert {
        margin-top: 1.25rem;
    }

    &--success {
        background-color: $color--success;
        border-left: .25rem solid darken($color--success, 15%);

        p, b {
            color: darken($color--success, 45%);
        }
    }

    &--info {
        background-color: $color--info;
        border-left: .25rem solid darken($color--info, 15%);

        p, b {
            color: darken($color--info, 45%);
        }
    }

    &--warning {
        background-color: $color--warning;
        border-left: .25rem solid darken($color--warning, 15%);

        p, b {
            color: darken($color--warning, 50%);
        }
    }

    &--fail {
        background-color: $color--fail;
        border-left: .25rem solid darken($color--fail, 15%);

        p, b {
            color: darken($color--fail, 45%);
        }
    }

    &__inner {
        padding: 1.25rem;
    }

    b {
        font-weight: 700;
    }
}