.c-footer {
    background: #f1f2f4;
    @include clearfix();
    padding: 1.25rem;
    width: 100%;
    
    @include media('<=small'){
        text-align: center;
    }

    &__copyright,
    &__nav {
        width: 100%;
    }

    &__copyright {
        font-size: .875rem;
        color: #aaa;
    }

    &__list {
        @include clearfix();
        float: right;
        width: auto;
        
        @include media('<=small'){
            width: 100%;
            margin-top: 1rem;
        }
    }

    &__item {
        float: left;
        margin-left: 1rem;
        
        @include media('<=small'){
            float: none;
            margin-left: 0;
        }

        &:first-child {
            padding-left: 0;
        }
    }

    &__link {
        color: #aaa;
        font-size: .875rem;
        text-decoration: none;

        &:hover {
            color: black;
        }
    }
}