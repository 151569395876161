.c-headline {
	margin-bottom: 1.875rem;

	h1, h2, h3 {
		color: $color--primary;
		font-size: $headline__size--h1;
	}

	&__center {
		display: block;
		text-align: center;
	}
}