.c-reseller {
    background: #3b3e64;
    
    @include media('<=small'){
        padding: 0 8.333333%;
    }
        
    &__inner {
        padding: 15rem 0;
        text-align: center;        
        
        @include media('<=medium'){
            padding: 8rem 0;
        }
        
        
        @include media('<=small'){
            padding: 2.5rem 0;
        }
    }
    
    &__text {
        &-headline {
            font-size: 3rem;
            color: #f06761;
            
            @include media('<=medium'){
                font-size: 2rem;
            }
            
            @include media('<=small'){
                font-size: 1.35rem;
            }
        }
        
        &-content {
            font-size: 1.5rem;
            line-height: 1.75;
            max-width: 85%;
            margin: 0 auto 3rem;
            color: #f06761;
            
            @include media('<=medium'){
                max-width: 100%;
                font-size: 1.15rem;
            }
            
            @include media('<=small'){
                font-size: 1rem;
                max-width: none;
                margin: 0 auto 2rem;
            }
        }
    }
}