html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  box-sizing: border-box;
  scroll-behavior: smooth;
}

html,
body {
  width: 100%;
  height: 100%;
  font-size: 100%;
}

body {
  background-color: $color__background--default;
  color: $color--secondary;
  font-family: $font__family--default;
  font-size: $font__size--default;
  font-style: $font__size--style;
  font-weight: $font__weight--default;
  line-height: $font__height--default;
  margin: 0;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

* {
  ::-moz-selection {
    background: $color--secondary;
    color: #fff;
  }
  ::selection {
    background: $color--secondary;
    color: #fff;
  }
}

.no-transition *,
.no-transition *:before,
.no-transition *:after {
  transition: none !important;
}

//IE 10 + Fix
@at-root {
  @-ms-viewport {
    width: device-width;
  }
}

//iOS "clickable elements" fix for role="button"
[role='button'] {
  cursor: pointer;
}

//Avoid 300ms click delay on touch devices that support the `touch-action` CSS property.
a,
area,
button,
[role='button'],
input,
label,
select,
summary,
textarea {
  touch-action: manipulation;
}

//Add the correct display in Edge, IE, IE 9 and Firefox.
article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section,
summary {
  display: block;
}

//Add the correct display in IE 9-.
audio,
canvas,
progress,
video {
  display: inline-block;
}

//Add the correct display in iOS 4-7.
audio:not([controls]) {
  display: none;
  height: 0;
}

//Add the correct vertical alignment in Chrome, Firefox, and Opera.
progress {
  vertical-align: baseline;
}

figure {
  margin: 0;
}

video {
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}

//Remove the gray background on active links in IE 10 and gaps in links underline in iOS 8+ and Safari 8+
a {
  background-color: transparent;
  -webkit-text-decoration-skip: objects;

  &:active,
  &:hover {
    outline-width: 0;
  }
}

p,
dd,
dl,
ul,
ol,
ul ul,
ol ul {
  color: $color--text;
  font-size: $font__family--default;
  margin: 0;
  padding: 0;
}

dfn,
i,
em {
  font-style: italic;
}

del {
  text-decoration: line-through;
}

li {
  padding: 0;
  list-style: none;
}

b,
strong {
  font-weight: 700;
  color: black;
}

a {
  background-color: transparent;
  outline: 0;

  &:active,
  &:hover {
    outline: 0;
  }
}

//Remove the margin in Firefox and Safari.
button,
input,
optgroup,
select,
textarea {
  font: inherit;
  margin: 0;
  line-height: inherit;
}

//Show the overflow in IE and Edge
button,
input {
  overflow: visible;
}

//Prevent a WebKit bug where destroys native `audio` and `video` controls in Android 4.
//Correct the inability to style clickable types in iOS and Safari.
button,
html [type='button'],
[type='reset'],
[type='submit'],
[type='email'],
[type='password'],
[type='number'] {
  -webkit-appearance: button;
}

//Remove the inner border and padding in Firefox.
button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button,
button:focus,
button:-moz-focusring,
[type='button']:-moz-focusring,
[type='reset']:-moz-focusring,
[type='submit']:-moz-focusring {
  outline: 0 none !important;
}

//Remove the default appearance of temporal inputs to avoid a Mobile Safari
input[type='date'],
input[type='time'],
input[type='datetime-local'],
input[type='month'] {
  -webkit-appearance: listbox;
}

//Correct the odd appearance in Chrome and Safari and correct the outline style in Safari.
[type='search'] {
  -webkit-appearance: textfield;
  outline-offset: -0.125rem;
}

[type='search']::-webkit-search-cancel-button,
[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}

[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  height: auto;
}

[type='number'] {
  -moz-appearance: textfield;
}

//Correct the inability to style clickable types in iOS and Safari.
::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

::-webkit-input-placeholder,
:-moz-placeholder,
::-moz-placeholder,
:-ms-input-placeholder {
  color: $color--secondary;
  opacity: 1;
}

select,
option {
  font-family: arial, helvetica, sans-serif;
}

select {
  width: 100%;
}

fieldset {
  border: 0;
  padding: 0;
  margin: 0;
  min-width: 0;
}

// Reset the entire legend element to match the `fieldset`
legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  width: 100%;
  padding: 0;
  max-width: 100%;
  font-size: $font__size--default;
  line-height: inherit;
  white-space: normal;
}

textarea {
  font-size: $font__size--default;
  overflow: auto;
  resize: vertical;
}

pre {
  background-color: $color--secondary;
  color: #fff;
  display: inline-block;
  padding: 0.3125rem 0.625rem;
  margin: 0;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

td,
th {
  padding: 0;
}

img {
  border: 0;
  display: inline-block;
  height: auto;
  max-width: 100%;
  user-select: none;
  vertical-align: middle;
  -ms-interpolation-mode: bicubic;
}

svg:not(:root) {
  overflow: hidden;
}

// Always hide an element with the `hidden` HTML attribute (from PureCSS).
[hidden] {
  display: none !important;
}
