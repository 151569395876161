.c-text {
	@include media("<=medium") {
		text-align: left;
	}

	@include media("<=small") {
		text-align: center;
	}

	&__australia {
		color: rgb(49, 49, 49);
	}

	&.is--fixed {
		height: 100%;
	}

	&__desc {
		display: none;

		.is--fixed & {
			@include media(">small") {
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
			}
		}
	}

	&__desc-headline {
		font-size: 4rem;

		@include media("<=medium") {
			font-size: 3rem;
		}

		@include media("<=small") {
			font-size: 2rem;
		}
	}

	&__tablet {
		position: relative;

		@include media("<=medium") {
			padding: 0 3rem;
			text-align: left;
			margin-top: -33%;
		}

		@include media("<=small") {
			margin-top: 0;
		}

		@include media("<=xsmall") {
			padding: 0 2rem;
			text-align: left;
		}
	}

	&__image {
		margin-bottom: 8rem;

		@include media("<=medium") {
			margin-bottom: 3rem;
		}

		@include media("<=small") {
			margin-bottom: 4rem;
			padding: 1rem;

			img {
				width: 100%;
			}
		}
	}

	&__attention {
		display: block;
		font-size: 1.15rem;
		text-transform: uppercase;
		letter-spacing: 0.15rem;
		font-weight: 700;
		margin-top: 2rem;

		@include media("<=medium") {
			font-size: 0.85rem;
		}

		&:before {
			content: "";
			display: inline-block;
			height: 0.2rem;
			background: black;
			width: 50px;
			vertical-align: middle;
			margin-right: 1rem;

			@include media("<=xsmall") {
				width: 25px;
			}

			@include media("<=medium") {
				display: none;
			}
		}
	}

	&__content-small {
		max-width: 45rem;

		@include media("<=medium") {
			max-width: none;
		}
	}

	h2 {
		font-size: 3rem;

		@include media("<=medium") {
			font-size: 2rem;
		}

		@include media("<=small") {
			font-size: 1.5rem;
		}
	}

	p {
		font-size: 1.5rem;
		line-height: 1.75;
		margin-bottom: 1rem;
		font-weight: 300;

		@include media("<=medium") {
			font-size: 1.15rem;
			margin: 0 15% 0 0;
		}

		@include media("<=small") {
			font-size: 1rem;
			margin: 0 1rem;
		}

		&.big-margin {
			margin-bottom: 3rem;
		}
	}

	a:not(.e-btn) {
		color: $color--primary;
		text-decoration: underline;
	}

	p,
	ul,
	ol,
	dl {
		ul,
		ol {
			padding-left: 1.25rem;
			margin-bottom: 0;
		}
	}

	ul li,
	ol li {
		position: relative;
		margin-bottom: 0.625rem;

		&:last-child {
			margin-bottom: 0;
		}

		ul,
		ol {
			padding-top: 0.625rem;
		}
	}

	ul {
		li {
			padding-left: 1.25rem;
			font-size: 1.5rem;
			font-weight: 300;

			@include media("<=medium") {
				font-size: 1.15rem;
			}

			@include media("<=small") {
				font-size: 1rem;
			}

			&:before {
				background-color: $color--text;
				border-radius: 50%;
				content: "";
				display: inline-block;
				height: 0.4rem;
				position: absolute;
				top: 0.825rem;
				left: 0;
				width: 0.4rem;

				@include media("<=medium") {
					font-size: 1rem;
					top: 0.4rem;
				}
			}

			li {
				&:before {
					background-color: transparent;
					border: 0.125rem solid $color--primary;
					border-radius: 50%;
					content: "";
					display: inline-block;
					height: 0.625rem;
					position: absolute;
					top: 0.425rem;
					left: 0;
					width: 0.625rem;
				}
			}
		}
	}

	ol {
		counter-reset: counter;

		li {
			padding-left: 1.625rem;

			&:before {
				background-color: $color--primary;
				border-radius: 0.125rem;
				content: counter(counter);
				counter-increment: counter;
				color: #fff;
				font-size: 0.875rem;
				display: inline-block;
				padding: 0 0.2875rem;
				position: absolute;
				top: 0.0625rem;
				left: 0;
			}
		}
	}

	dt,
	dd {
		margin-bottom: 0.625rem;
	}

	dt {
		font-weight: 700;
	}

	dd {
		@include icon(before, "angle-right");
		padding-left: 1.25rem;

		&:before {
			margin-right: 0.625rem;
		}
	}

	table {
		margin-bottom: 1.25rem;
		width: 100%;
	}

	th,
	td {
		pre,
		p,
		ul,
		ol,
		ul,
		ol ol,
		ul ul,
		ol ol li,
		ul li li {
			margin-bottom: 0;
		}
	}

	th,
	td {
		border-bottom: 0.0625rem solid $color--light;
		text-align: left;
	}

	th {
		padding: 0 0 0.3125rem;
	}

	td {
		padding: 0.625rem 0;
	}

	pre {
		margin-bottom: 1.5rem;
	}

	strong,
	b {
		font-weight: 700;
	}

	> *:last-child {
		margin-bottom: 0;
	}

	&__desc-content {
		position: relative;
		z-index: 999;

		@include media("<=medium") {
			margin: 0 0 1rem !important;
			max-width: none;
		}

		@include media("<=small") {
			margin: 0 0 1rem !important;
			max-width: 75%;
		}

		@include media("<=xsmall") {
			margin: 0 0 1rem !important;
			max-width: none;
		}
	}

	&__desc-list li {
		z-index: 999;
		position: relative;

		@include media("<=medium") {
			font-size: 1rem;
		}
	}

	&__alternate {
		p {
			font-size: 1.25rem;
		}
	}

	&__center {
		@include media("<=medium") {
			text-align: center;

			p {
				margin: 0 5%;
				margin-bottom: 1rem;
			}
		}
	}
}
