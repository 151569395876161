.c-buy {
    padding-right: 1.75rem;
    text-align: right;
    vertical-align: middle;

    @include media('<=medium'){
        position: absolute;
        right: 1rem;
        padding-right: 0;
        top: 50%;
        transform: translateY(-50%);
    }
    
    @media screen and (max-width: 767px){
        padding: 1.5rem;
    }
    
    @media screen and (max-width: 767px){
        position: static;
        transform: none;
        text-align: center;
    }
}