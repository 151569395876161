@charset "UTF-8";

// ====================================================================================
// Settings : Variables and other settings
// ====================================================================================

@import "settings/variables";

// ====================================================================================
// Generic : Font-face, box-sizing, normalize, etc.
// ====================================================================================

@import "generic/fonts";
@import "generic/reset";
@import "generic/print";

// ====================================================================================
// Tools : Custom functions and mixins
// ====================================================================================

@import "tools/mixins";
@import "tools/utilities";

// ====================================================================================
// Objects : Layout and structure classes
// ====================================================================================

@import "objects/grid";
@import "objects/content";
@import "objects/userbar";

// ====================================================================================
// Elements : Bare element defaults like headings and links
// ====================================================================================

@import "elements/links";
@import "elements/buttons";
@import "elements/animation";
@import "elements/headlines";
@import "elements/alert";
@import "elements/form";
@import "elements/loader";
@import "elements/nav";
@import "elements/layer";


// ====================================================================================
// Components : Individual components - layout
// ====================================================================================

@import "components/layout/header";
@import "components/layout/logo";
@import "components/layout/navigation";
@import "components/layout/hero";
@import "components/layout/teaser";
@import "components/layout/infographic";
@import "components/layout/family";
@import "components/layout/logowall";
@import "components/layout/reseller";
@import "components/layout/breadcrumb";
@import "components/layout/main";
@import "components/layout/footer";

// ====================================================================================
// Components : Individual components - widgets
// ====================================================================================

@import "components/widgets/headline";
@import "components/widgets/text";
@import "components/widgets/image";
@import "components/widgets/article";
@import "components/widgets/slider";
@import "components/widgets/buy";
@import "components/widgets/view";
@import "components/widgets/colorpicker";
@import "components/widgets/pagination";
@import "components/widgets/video";

// ====================================================================================
// Trumps : Utilities and other rules meant to be a final trump over everything else
// ====================================================================================

@import "trumps/hacks";

// ====================================================================================
// Vendor : 3rd party styles
// ====================================================================================

//@import "vendor/*"