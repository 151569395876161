.c-colorpicker {
  &__inner {
    padding: 1.75rem 1.5rem 1.05rem;
    @include clearfix;

    @media screen and (max-width: 767px) {
      padding: 1.5rem;
      text-align: center;
      width: 100%;
    }
  }

  @media screen and (max-width: 767px) {
    text-align: center;
  }

  &__title {
    margin: 0;
    font-size: 1rem;
    line-height: 2rem;
    display: inline-block;
    margin-right: 1rem;
    float: left;

    @include media('<=medium') {
      font-size: 0.8rem;
      display: none;
    }
  }

  &__list {
    display: none;

    &.is--active {
      display: inline-block;
    }

    &-item {
      float: left;
      margin: 0 0.1rem;
      height: 1.2rem;
      width: 1.2rem;
    }

    &-button {
      backface-visibility: hidden;
      border-radius: 300px;
      border: 0;
      padding: 0;
      cursor: pointer;
      display: inline-block;
      height: 1.2rem;
      position: relative;
      transition: $transition--fast;
      width: 1.2rem;
      margin: 0.2rem 0;

      &:hover {
        transform: scale(1.25);
        transition: 0.25s ease all;
      }

      &.is--active {
        transform: scale(0.75);
      }

      &.is--kt {
        background-color: #55cfdc;
      }

      &.is--cc {
        background-color: #eaebef;
      }

      &.is--td {
        background-color: #15100d;
      }

      &.is--lg {
        background-color: #4573b1;
      }

      &.is--cd {
        background-color: #123325;
      }

      &.is--do {
        background-color: #2c2a42;
      }

      &.is--wi {
        background-color: #5d1724;
      }

      &.is--ka {
        background-color: #787878;
      }

      &.is--tf {
        background-color: #69c464;
      }

      &.is--cr {
        background-color: #d55053;
      }

      &.is--co {
        background-color: #123123;
      }

      &.is--br {
        background-color: #009fc3;
      }
    }
  }
}
