.c-teaser {
    background: red;
        
    &__full {
        height: 70vh;
        line-height: 70vh;
        
        &-uni {
            background: #f1f1f2 url("../img/content/unisex.jpg") no-repeat center top / cover;
            
            @include media('<=small'){
                background-size: cover;
            }
            
            
            @include media('<=medium'){
                background-size: cover;
            }
        }
    }
    
    &__half {
        height: 35vh;
        line-height: 35vh;
        
        @include media('<=small'){
            height: 15rem;
            line-height: 15rem;
        }
        
        &-men {
            background: #d6e0f2 url("../img/content/men.jpg") no-repeat center top / cover; 
            
            @include media('<=small'){
                background-size: cover;
            }
            
            @include media('<=medium'){
                background-size: cover;
            }
        } 
        
        &-wom {
            background: #f3d0dd url("../img/content/women.jpg") no-repeat center top / cover;
            
            @include media('<=small'){
                background-size: cover;
            }            
            
            @include media('<=medium'){
                background-size: cover;
            }
        }
    }
    
    &__text {
        text-align: center;
        display: block;
        font-size: 4rem;
        font-weight: 600;
        color: #3b3e64;
        
        > a {
            text-decoration: none;
            font-size: 4rem;
            font-weight: 600;
            color: #3b3e64;
        
            @include media('<=small'){
                font-size: 2rem;
            }
        }
        
        @include media('<=small'){
            font-size: 2rem;
        }
    }
}