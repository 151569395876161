.c-hero {
  height: 100vh;
  padding: 0 10rem;
  background: white;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;

  @include media('<=medium') {
    height: calc(100vh - 4.688rem);
    text-align: left;
    padding: 0 5rem;
  }

  @include media('<=large') {
    padding: 0 5rem;
  }

  @include media('<=small') {
    height: calc(50vh - 4.688rem);
    text-align: left;
    padding: 0 5rem;
  }

  @include media('<=xsmall') {
    height: auto;
    padding: 2rem;
    text-align: center;
  }

  @media screen and (max-width: 767px) {
    height: auto;
    padding: 3rem;
  }

  &.c-hero__alternative {
    &:after {
      content: '';
      display: block;
      left: 50%;
      right: 0;
      top: 0;
      bottom: 0;
      background: #cadbeb url('../img/content/stage2.png') no-repeat center
        center / contain;
      position: absolute;
      z-index: 0;

      @media screen and (orientation: portrait) and (min-width: 1025px) {
        left: 0;
        background: #cadbeb;
      }

      @media screen and (max-width: 767px) {
        display: none;
      }

      @include media('<=medium') {
        left: 25%;
        background-size: 65% auto;
        background-position: center right;
        background: #cadbeb url('../img/content/stage2.png') no-repeat;
      }

      @include media('<=small') {
        height: 60vh;
        background: url('../img/content/stage2.png') no-repeat;
        background-size: 65% auto;
        background-position: center right;
      }

      @include media('<=xsmall') {
        display: none;
      }
    }

    .c-hero__desc-header {
      color: #3cd0ea;
    }

    .c-hero__desc-content {
      color: #828491;
    }
  }

  &:after {
    content: '';
    display: block;
    left: 50%;
    right: 0;
    top: 0;
    bottom: 0;
    background: #dfeee0 url('../img/content/stage.png') no-repeat center center /
      cover;
    position: absolute;
    z-index: 0;

    @media screen and (orientation: portrait) and (min-width: 1025px) {
      left: 0;
      background: #dfeee0;
    }

    @media screen and (max-width: 767px) {
      display: none;
    }

    @include media('<=medium') {
      left: 25%;
      background-size: 65% auto;
      background-position: center right;
      background: #dfeee0 url('../img/content/stage.png') no-repeat;
    }

    @include media('<=small') {
      height: 60vh;
      background: url('../img/content/stage.png') no-repeat;
      background-size: 65% auto;
      background-position: center right;
    }

    @include media('<=xsmall') {
      display: none;
    }
  }

  &__inner {
    width: 100%;
    position: relative;
    z-index: 10;
  }

  &__desc {
    &__alert {
      margin-left: 15vw;
      padding-bottom: 2rem;
      img {
        width: 35vw;
      }
      @include media('<=medium') {
        margin-left: 0;

        img {
          width: 50vw;
        }
      }
      @include media('<=xsmall') {
        margin-left: 0;

        img {
          width: 100vw;
        }
      }
    }
    &-header {
      font-size: 16rem;
      line-height: 1;
      color: #5d1724;

      @media screen and (orientation: portrait) and (min-width: 1025px) {
        text-align: center;
      }

      @include media('<=xxlarge') {
        font-size: 11rem;
      }

      @include media('<=xlarge') {
        font-size: 8rem;
      }

      @include media('<=medium') {
        font-size: 5rem;
      }

      @media screen and (max-width: 767px) {
        text-align: center;
      }

      @include media('<=small') {
        font-size: 3rem;
      }
    }

    &-content {
      font-size: 1.65rem;
      line-height: 1.75;
      max-width: 45rem;
      color: #829183;

      @media screen and (orientation: portrait) and (min-width: 1025px) {
        max-width: 85%;
        margin: 0 auto;
        text-align: center;
      }

      @include media('<=xlarge') {
        font-size: 1.4rem;
        max-width: 35rem;
      }

      @include media('<=medium') {
        font-size: 1.2rem;
        max-width: 50%;
      }

      @media screen and (max-width: 767px) {
        max-width: 75%;
        margin: 0 auto;
        text-align: center;
      }

      @include media('<=xsmall') {
        font-size: 1rem;
        max-width: none;
      }
    }
  }
}
