.c-slider {
  text-align: center;

  &__wrap {
    height: 0;
    padding-bottom: 100%;
    position: relative;
    overflow: hidden;

    @include media('<=small') {
      padding-bottom: 72rem;
    }

    @media screen and (orientation: landscape) and (max-width: 767px) {
      padding-bottom: 72rem;
    }

    @include media('<=xsmall') {
      padding-bottom: 230%;
    }

    @media screen and (orientation: portrait) and (max-width: 450px) {
      padding-bottom: 230%;
    }
    &:after {
      background-color: rgba(255, 255, 255, 0.6);
      content: '';
      display: block;
      height: 100%;
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
      transition: $transition--fast;
      visibility: hidden;
      width: calc(100% - 10%);
      z-index: 105;
    }

    &.is--loading:after {
      opacity: 1;
      visibility: visible;
    }
  }

  &__slide {
    opacity: 0;
    position: absolute;
    left: 57.5%;
    transform: translateX(-50%);
    transition: $transition--fast;
    visibility: hidden;
    z-index: 1;
    height: 100%;
    padding-right: 56.754596322%;
    width: 0;

    @include media('<=medium') {
      width: 100%;
      left: 50%;
      padding-right: 0;
    }

    @include media('<=small') {
      width: 100%;
      max-width: 100%;
    }

    &.is--active {
      opacity: 1;
      visibility: visible;
      z-index: 2;
    }
  }

  &__picture {
    position: relative;
    z-index: 1;
    height: 100%;

    img {
      max-width: none;
      height: 100%;

      @include media('<=small') {
        height: auto;
        max-width: 100%;
      }
    }
  }

  &__top,
  &__bottom {
    z-index: 2;
  }

  &__bottom {
    position: absolute;
    left: 60%;
    bottom: 35%;

    @include media('<=medium') {
      left: 50%;
    }

    @include media('<=small') {
      position: static;
      right: 0;
      top: 0;
      margin: 1rem 0;

      &:before {
        content: 'Bottom:';
        margin-right: 0.5rem;
        font-size: 1.15rem;
      }
    }

    > i {
      height: 3rem;
      width: 3rem;
      border: 0.5rem solid white;
      display: inline-block;
      border-radius: 300px;
      background: red;
      vertical-align: middle;
      cursor: pointer;
      position: relative;

      @include media('<=xsmall') {
        height: 1.5rem;
        width: 1.5rem;
        border: 0;
      }

      &:after {
        background: url('../img/icons/color.png') no-repeat center center;
        content: '';
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        position: absolute;

        @include media('<=small') {
          content: none;
        }
      }
    }

    > span {
      height: 1rem;
      width: 1rem;
      position: relative;
      margin: 0 0.15rem;
      display: inline-block;
      cursor: pointer;
      border-radius: 300px;
      background: red;
      vertical-align: middle;

      @include media('<=xsmall') {
        height: 1.5rem;
        width: 1.5rem;
        border: 0;
      }
    }
  }

  &__top {
    position: absolute;
    right: 60%;
    top: 18.5%;

    @include media('<=medium') {
      right: 53%;
    }

    @include media('<=small') {
      position: static;
      right: 0;
      top: 0;
      margin-top: 2rem;

      &:before {
        content: 'Top:';
        margin-right: 0.5rem;
        font-size: 1.15rem;
      }
    }
  }

  &__nav {
    position: relative;

    @include media('<=small') {
      display: inline-block;
    }
  }

  &__flap {
    height: 3rem;
    width: 3rem;
    padding: 0;
    border: 0.5rem solid white;
    display: inline-block;
    border-radius: 300px;
    vertical-align: middle;
    cursor: pointer;
    position: relative;
    transition: $transition--fast;
    z-index: 5;

    @include media('<=medium') {
      height: 2.5rem;
      width: 2.5rem;
    }

    @include media('<=small') {
      height: 1.5rem;
      width: 1.5rem;
      border: 0;
    }

    @include media('<=medium') {
      position: relative;
      display: none;
    }

    &:after {
      background: url('../img/icons/color.png') no-repeat center center;
      content: '';
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      position: absolute;
      @include media('<=xsmall') {
        content: none;
      }
    }

    &[data-state='td'] {
      background-color: #15100d;
    }

    &[data-state='lg'] {
      background-color: #4573b1;
    }

    &[data-state='cd'] {
      background-color: #123325;
    }

    &[data-state='do'] {
      background-color: #2c2a42;
    }

    &[data-state='wi'] {
      background-color: #5d1724;
    }

    &[data-state='ka'] {
      background-color: #787878;
    }

    &[data-state='tf'] {
      background-color: #69c464;
    }

    &[data-state='cr'] {
      background-color: #d55053;
    }

    &[data-state='co'] {
      background-color: #123123;
    }

    &[data-state='br'] {
      background-color: #009fc3;
    }
  }

  &__color {
    height: 1rem;
    width: 1rem;
    margin: 0 0.15rem;
    display: inline-block;
    cursor: pointer;
    border: 0;
    border-radius: 300px;
    opacity: 0;
    padding: 0;
    position: absolute;
    top: 1.125rem;
    transition: $transition--fast;
    transform: translateX(0);
    visibility: hidden;
    vertical-align: middle;
    @include media('<=medium') {
      top: 0.78rem;
    }

    @include media('<=small') {
      height: 1.5rem;
      width: 1.5rem;
      position: static;
      opacity: 1;
      transform: none !important;
      visibility: visible;
    }

    .is--right & {
      right: 0;
    }

    .is--left & {
      left: 0;
    }

    .is--open.is--right &,
    .is--open.is--left & {
      opacity: 1;
      visibility: visible;
    }

    .is--open.is--right & {
      &:nth-child(1) {
        transform: translateX(-3.125rem);
      }

      &:nth-child(2) {
        transform: translateX(-4.625rem);
      }

      &:nth-child(3) {
        transform: translateX(-6.125rem);
      }

      &:nth-child(4) {
        transform: translateX(-7.625rem);
      }

      &:nth-child(5) {
        transform: translateX(-9.125rem);
      }

      &:nth-child(6) {
        transform: translateX(-10.625rem);
      }

      &:nth-child(7) {
        transform: translateX(-12.125rem);
      }

      &:nth-child(8) {
        transform: translateX(-13.625rem);
      }

      @include media('<=small') {
        transform: none !important;
      }
    }

    .is--open.is--left & {
      &:nth-child(2) {
        transform: translateX(3.125rem);
      }

      &:nth-child(3) {
        transform: translateX(4.625rem);
      }

      &:nth-child(4) {
        transform: translateX(6.125rem);
      }

      &:nth-child(5) {
        transform: translateX(7.625rem);
      }

      &:nth-child(6) {
        transform: translateX(9.125rem);
      }

      &:nth-child(7) {
        transform: translateX(10.625rem);
      }

      &:nth-child(8) {
        transform: translateX(12.125rem);
      }

      &:nth-child(9) {
        transform: translateX(13.625rem);
      }
    }

    &.is--kt {
      background-color: #55cfdc;
    }

    &.is--cc {
      background-color: #eaebef;
    }

    &.is--td {
      background-color: #15100d;
    }

    &.is--lg {
      background-color: #4573b1;
    }

    &.is--cd {
      background-color: #123325;
    }

    &.is--do {
      background-color: #2c2a42;
    }

    &.is--wi {
      background-color: #5d1724;
    }

    &.is--ka {
      background-color: #787878;
    }

    &.is--tf {
      background-color: #69c464;
    }

    &.is--cr {
      background-color: #d55053;
    }

    &.is--co {
      background-color: #123123;
    }

    &.is--br {
      background-color: #009fc3;
    }
  }

  &__gender {
    position: absolute;
    left: 15%;
    top: 50%;
    transform: translateY(-50%);
    z-index: 300;
    @include media('<=small') {
      position: static;
      left: 0;
      top: 0;
      transform: none;
      z-index: 0;
      text-align: center;
    }

    &-item {
      border: 0;
      height: 4rem;
      width: 4rem;
      position: relative;
      display: block;
      margin: 1rem 0;
      cursor: pointer;
      border-radius: 300px;
      transition: $transition--fast;
      @include media('<=small') {
        display: inline-block;
        margin: 1rem 0.25rem;
      }

      &.is--male {
        background: #a5c0ed url('../img/icons/Form 693.png') no-repeat center
          center;

        &.is--active {
          box-shadow: 0 8px 25px rgba(#a5c0ed, 0.75);
        }
      }

      &.is--female {
        background: #e8a6ca url('../img/icons/Form 685.png') no-repeat center
          center;

        &.is--active {
          box-shadow: 0 5px 10px rgba(#e8a6ca, 0.75);
        }
      }
    }
  }
}
