h1, h2, h3, h4, h5, h6 {
    font-weight: 700;
    line-height: 1.4;
    margin: 0;
}

h1 {
    font-size: $headline__size--h1;
    margin-bottom: 1.25rem;
    hyphens: auto;
}

h2 {
    font-size: $headline__size--h2;
    margin-bottom: .9375rem;
}

h3 {
    font-size: $headline__size--h3;
    margin-bottom: .625rem;
}

h4 {
    font-size: $headline__size--h4;
    margin-bottom: .3125rem;
}

h5 {
    font-size: $headline__size--h5;
}

h6 {
    font-size: $headline__size--h6;
}